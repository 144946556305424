<template>
    <div>
        <div>
            <h2>基本信息</h2>
            <!-- {{$store.state.user}} -->
            <!-- {{this.user_info.avatar}} -->
        </div>
        <div style="margin:10px 60px">
            <img :src="user_info.avatar" alt="" class="avatar">
            <el-button>
                <el-upload
                    :action="uploadUrl+'?user_id='+user_info.id"
                    :auto-upload="true"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                >
                上传头像
                </el-upload>
            </el-button>
            <!-- 这里的uploadUrl是http://127.0.0.1:8000/media/upload/uploadAvatar -->
            
        </div>
        <div style="width:50%">
            <el-form ref="form" :model="user_info" label-width="80px" >
                <el-form-item label="账号">
                    <el-input v-model="user_info.username"  disabled></el-input>
                </el-form-item>
                <el-form-item label="ID">
                    <el-input v-if="$store.state.user.vip" :placeholder="user_info.id + ' (VIP) '"  disabled></el-input>
                    <el-input v-else :placeholder="user_info.id"  disabled></el-input>
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="user_info.nickname"></el-input>
                </el-form-item>
                <el-form-item label="职业">
                    <el-input v-model="user_info.profession"></el-input>
                </el-form-item>
                <el-form-item label="专业">
                    <el-input v-model="user_info.major"></el-input>
                </el-form-item>
                <el-form-item label="生日">
                    <el-date-picker type="date" placeholder="选择日期" v-model="user_info.birthday" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item>
                     <input type="button" class="save_btn" value="保存" @click="updateUserInfo">
                    <!-- <button @click="updateUserInfo" class="save_btn">保存</button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        user_info: {
          username: '',
          nickname: '',
          profession: '',
          major: '',
          birthday: '',
        },
        uploadUrl: '/user/avatar/',
      }
    },
    mounted(){
        this.getUserInfo()
    },
    methods: {
        // 上传成功后进行数据处理
        handleAvatarSuccess(res) {
            // 显示上传是否成功
            if (res.code == 200) {
                this.user_info.avatar = res.avatar
            } else {
                this.$message.error(res.msg);
            }
            console.log(res)
            // 规定新的imageUrl为 <"http://127.0.0.1:8000/media/user/"+文件名>
            
        },
        
        // 上传前判断图片大小是否满足要求
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        getUserInfo(){
            axios.get('/user/info/', {headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                console.log(response)
                this.user_info = response.data.user
            }).catch((err) => {
                console.log(err)
            });
        },
        updateUserInfo(){
            axios.post('/user/info/', this.user_info,{headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                console.log(response)
                this.user_info = response.data.user
                localStorage.setItem('user', JSON.stringify(this.user_info))
                this.$store.commit('setUser', this.user_info)
                this.$message.success('保存成功')
                //刷新页面
                this.$router.push('/')

            }).catch((err) => {
                console.log(err)
            });
        },
        
    }
  }
</script>

<style lang="scss" scoped>

.menu_item{
    width: 100%;
    height: 40px;
    margin: 20px 0;
    font-size: 24px;
    cursor: pointer;
}
.menu_item:hover{
    border-left: 3px solid black;
}

.avatar{
    width: 60px;
    border-radius: 30px;
}

.el-button{
    position: relative;
    bottom: 20px;
    left: 50px;
}

.save_btn{
    width: 100px;
    height: 40px;
    font-size: 18px;
    background-color: black;
    color:white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}
</style>